import React from "react";

import { Box, Typography } from "@material-ui/core";
import { ErrorSharp } from "@material-ui/icons";

import Button from "@remar/shared/dist/components/Button";

import CartItem from "../CartItem";
import { CartNoBooks } from "../styles";

const CartContent = ({ cart, checkoutModal, addBook, removeBook }) => (
	<Box my={4}>
		{!!cart.length ? (
			<Box maxHeight={300} overflow={"scroll"}>
				{cart.map(item => (
					<CartItem key={item.id} item={item} addBook={addBook} removeBook={removeBook} />
				))}
			</Box>
		) : (
			<CartNoBooks>
				<ErrorSharp className={"info"} />
				<Typography>You haven’t selected any physical book for purchase to process for checkout.</Typography>
			</CartNoBooks>
		)}
		<Box mt={2}>
			<Button
				fullWidth
				variant={"filled"}
				color={"primary"}
				disabled={cart.length === 0}
				onClick={() => checkoutModal(true)}
			>
				Checkout
			</Button>
		</Box>
	</Box>
);

export default CartContent;
