import React, { useEffect, useMemo } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTimeLeftCountDown } from "@remar/shared/dist/hooks/useTimeLeftCountDown";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import clsx from "clsx";
import { addMinutes, isAfter } from "date-fns";

import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	hideBannerOnCancelSub,
	selectInactiveSubscription,
	selectIsTrial,
	setPaymentDiscount,
	setStateValue,
	setUpgradeModal
} from "store/features/Auth/authSlice";
import { IBannerData } from "store/features/notifications/notifications.model";
import { setBannerActionLoading, setBannerData } from "store/features/notifications/notifications.slice";

import { BannerContainer, useStyles } from "./styles";

import { GLOBAL_CONSTANTS } from "../../../constants";

interface ISaleBanner {
	startDate: string;
	endDate: string;
	copy: string;
	background: string;
}

type SaleBannerProps = Pick<ISaleBanner, "copy">;
const SaleBanner = ({ copy }: SaleBannerProps) => <Box>{decodeURIComponent(atob(copy))}</Box>;

const Banner = ({ bannerData }: { bannerData: IBannerData }) => {
	const {
		bannerMessage,
		duration,
		createdAt,
		expiresAt,
		isDismissible = true,
		coupon,
		paymentAndDiscount,
		action,
		isActionLoading
	} = bannerData;
	const classes = useStyles();
	const dispatch = useAppDispatch();

	const isTrial = useAppSelector(selectIsTrial);
	const trial = bannerData.isTrial ?? isTrial;
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);

	const { onStart, timeLeft, isStarted } = useTimeLeftCountDown();

	useEffect(() => {
		if (duration && createdAt) {
			const { days = 0, hours = 0, minutes = 0 } = duration;
			const daysToMinutes = days * 24 * 60;
			const hoursToMinutes = hours * 60;
			const totalMinutes = daysToMinutes + hoursToMinutes + minutes;
			onStart(addMinutes(new Date(createdAt), totalMinutes));
		}
	}, [duration, createdAt, onStart]);

	const saleBannersToShow = useMemo(() => {
		const { SALE_BANNERS } = GLOBAL_CONSTANTS;
		return SALE_BANNERS
			? (JSON.parse(SALE_BANNERS) as Array<ISaleBanner>).filter(({ endDate, startDate }) => {
					const now = new Date();
					return new Date(startDate) < now && new Date(endDate) > now;
			  })
			: [];
	}, []);

	const canApplyCoupon = useMemo(
		() => isStarted || (expiresAt && isAfter(new Date(expiresAt), new Date())),
		[isStarted, expiresAt]
	);
	const bannerMessageText = useMemo(() => {
		if (trial && !isRenewEnabled) {
			if (saleBannersToShow.length) {
				return <SaleBanner {...saleBannersToShow[0]} />;
			}
		}
		return bannerMessage;
	}, [trial, isRenewEnabled, saleBannersToShow, bannerMessage]);

	const showCloseIcon = useMemo(() => !trial, [trial]);

	return (
		<>
			<Box
				onClick={() => {
					if (isActionLoading) {
						return;
					}
					if (action) {
						dispatch(setBannerActionLoading(true));
						return action();
					}
					if (!!coupon && canApplyCoupon) {
						dispatch(applyValidatedCoupon(coupon));
					} else if (!!paymentAndDiscount) {
						dispatch(setPaymentDiscount(paymentAndDiscount));
					} else {
						dispatch(setStateValue({ key: "validatedCoupon", value: null }));
					}

					dispatch(setUpgradeModal(true));
				}}
				bgcolor={
					trial && !isRenewEnabled && saleBannersToShow.length && saleBannersToShow[0].background + "! important"
				}
				className={clsx(
					classes.defaultNotice,
					trial && !isRenewEnabled && classes.trialNotice,
					isActionLoading && classes.disableBanner
				)}
			>
				<Box display={"flex"} alignItems={"center"} justifyContent="center">
					<BannerContainer>
						<Typography className={clsx(classes.bannerMessage, trial && !isRenewEnabled && classes.trialText)}>
							{bannerMessageText}
						</Typography>
					</BannerContainer>
					<Typography style={{ marginLeft: "10px" }} className={classes.trialText} align={"center"}>
						{timeLeft}
					</Typography>
				</Box>
				{isActionLoading ? (
					<Box width={40} display={"flex"} justifyContent={"center"} alignItems={"center"}>
						<ContentLoader size={"1rem"} height={"30px"} />
					</Box>
				) : (
					showCloseIcon &&
					isDismissible && (
						<IconButton
							size="small"
							className={classes.closeNotice}
							onClick={e => {
								dispatch(setBannerData({ showBanner: false }));
								dispatch(hideBannerOnCancelSub());
								e.stopPropagation();
							}}
						>
							<Close />
						</IconButton>
					)
				)}
			</Box>
		</>
	);
};

export default Banner;
